var menu = [];

let items = [

    // {
    //     title: 'Finance',
    //     icon: 'TrendingUpIcon',
    //     route: 'finance-manage',
    //     codename:'view_sellerfinance'
    // },
    {
        title: 'Seller Finance',
        icon: 'TrendingUpIcon',
        route: 'finance-seller',
        codename:'view_sellerfinance'
    },
    {
        title: 'Withdrawal',
        icon: 'ZapIcon',
        route: 'finance-withdraw',
        codename:'view_sellerbalancewithdraw'
    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;
