<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
          class="ml-25"
          href="https://www.facebook.com/CloudX-104684891734477"
          target="_blank"
      >CloudX</b-link> by
      <b-link
          class="ml-25"
          href="https://www.facebook.com/catchbitflex"
          target="_blank"
      >BitFlex
      </b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
      /> For you, From us

    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
