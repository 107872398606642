import menu from "@/navigation/vertical/nextcart/affiliate";

export default [
    {
        header: 'Sourcing',
    },
    {
        title: 'Product Brands',
        icon: 'CoffeeIcon',
        route: 'product-source',
        codename: 'view_productsource'

    },
    {
        title: 'Product Distributors',
        icon: 'CoffeeIcon',
        route: 'product-source-distributor',
        codename: 'view_productsource'

    }

]