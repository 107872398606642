var menu = [];

let items = [

    // {
    //     title: 'Finance',
    //     icon: 'TrendingUpIcon',
    //     route: 'finance-manage',
    //     codename:'view_sellerfinance'
    // },
    {
        title: 'Seller Shipping Finance',
        icon: 'NavigationIcon',
        route: 'shipping-finance-seller',
        codename:'view_sellershippingfinance'
    },
    {
        title: 'Shipping Withdrawal',
        icon: 'Navigation2Icon',
        route: 'shipping-charge-withdraw',
        codename:'view_sellershippingwithdraw'
    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;
