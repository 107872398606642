var menuItems = [];
var menu=[]
let items=  [
    {
        title: 'Deals Products',
        route: 'deals-products',
        codename:'view_dealsoftheday',

    },
    {
        title: 'Deals Settings',
        route: 'deals-settings',
        codename:'view_dealsettings',
    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})

if(menuItems.length!==0) {
    menu.push({
        title: 'Deals Of the day',
        icon: 'GiftIcon',
        children: menuItems
    })
}
export default menu;
