var menu = [
    {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'CodesandboxIcon',
    },
];

let items = [

    {
        title: 'Onboard Businesses',
        route: 'business-list',
        icon: 'ShoppingBagIcon',
        codename: 'view_businesscore'

    },
    {
        title: 'Customer Base',
        icon: 'UserIcon',
        route:'customer-base',
        codename: 'view_useraccount'

    }

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;
