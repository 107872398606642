var menuItems = [];
var menu=[]
let items= [
    {
            title: 'Day Limit',
            route: 'day-limit',
            codename:'view_daylimit'
        },
    {
        title: 'Permission Group',
        route: 'permission-group',
        codename:'view_permissiongroup'
    },
    {
        title: 'Permission List',
        route: 'permission-list',
        codename:'view_permissionlist'
    },
    {
        title: 'Feature List',
        route: 'feature-list',
        codename:'view_featurelist'
    },
    {
        title: 'Permission Listing',
        route: 'permission-listing',
        codename:'view_permissionlisting'
    },
    {
        title: 'Plan List',
        route: 'plan-list',
        codename:'view_planlist'
    },
    {
        title: 'Pricing List',
        route: 'pricing-list',
        codename:'view_pricinglist'
    },
    {
        title: 'Selected Subscription List',
        route: 'selected-pricing-list',
        codename:'view_pricinglist'
    },
    // {
    //         title: 'Business Onboard',
    //         route: 'business-add',
    //         codename:'view_businesscore'
        // },
    // {
    //         title: 'Theme',
    //         route: 'theme',
    //         codename:'view_theme'
    //     },
    //     // {
        //     title: 'Country',
        //     route: 'database-country',
        //     codename:'view_country'
        //
        // },
        // {
        //     title: 'State',
        //     route: 'database-state',
        //     codename:'view_state'
        // },
        //
        // {
        //     title: 'Area',
        //     route: 'database-area',
        //     codename:'view_area'
        // },
        // {
        //     title: 'Product Categories',
        //     route: 'product-categories',
        //     codename:'view_category'
        // }

    ]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })

// if(menuItems.length!==0) {
    menu.push({
        title: 'Subscription Management',
        icon: 'CalendarIcon',
        children: items
    })
// }
export default menu;
