
var menuItems = [];
var menu=[]
let items= [
    {
        title: 'FAQ Categories',
        route: 'faq-categories',
        codename:'view_faqcategory',
    },
    {
        title: 'FAQs',
        route: 'faq-question',
        codename:'view_faq',
    },


]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})

if(menuItems.length!==0) {
    menu.push({
        title: 'FAQ Section',
        icon: 'HelpCircleIcon',
        children: menuItems
    })
}
export default menu;


