
var menuItems = [
    {
        title: 'Account Setting',
        route: 'pages-account-setting'
    },
];
var menu=[]
let items= [
    {
        title: 'Site Settings',
        route: 'site-settings',
        codename:'view_siteinfo'
    },
    {
        title: 'Site Features',
        route: 'site-features',
        codename:'view_sitefeatures'
    },
    {
        title: 'Shop Features',
        route: 'shop-features',
        codename:'view_shopfeatures'
    },
    {
        title: 'Maintenance Mode',
        route: 'maintenance-settings',
        codename:'view_siteinfo'
    },
    {
        title: 'Price Update Toggle',
        route: 'price-settings',
        codename:'change_product'
    },
    {
        title: 'Time Limit',
        route: 'time-limit',
        codename:'change_paymentsettings'
    },
    {
        title: 'SEO Settings',
        route: 'seo-settings',
        codename:'view_siteseosettings'
    },
    {
        title: 'Facebook Meta',
        route: 'facebook-meta',
        codename:'view_facebookmetasettings'
    },
    {
        title: 'Google Analytics',
        route: 'google-analytics',
        codename:'view_googleanalytics'
    }


]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})
menu.push( {
    header: 'Settings',
},)
if(menuItems.length!==0) {
    menu.push({
        title: 'Settings',
        icon: 'SettingsIcon',
        children: menuItems
    })
}
export default menu;
