<template>
  <div >
    <b-row v-if="summary">
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="TruckIcon"
            :statistic="'৳ '+summary.total_shipping_charge"
            statistic-title="Total Shipping Charge"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="HeartIcon"
            color="success"
            :statistic="'৳ '+summary.received_amount"

            statistic-title="Total Received Amount"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="ArrowUpIcon"
            color="warning"
            :statistic="'৳ '+summary.pending_withdraw_request"
            statistic-title="Pending Withdraw Request"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="DollarSignIcon"
            color="danger"
            :statistic="'৳ '+summary.current_shipping_balance"
            statistic-title="Current Shipping Balance"
        />
      </b-col>


    </b-row>
    <b-row v-if="shippingFinance">
      <b-col
          lg="12"
      >
        <b-card title="Shipping Charge Details">

          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group class="justify-content-start">
                      <div class="d-flex align-items-center">
                        <b-col md="12">
                          <v-select
                              id="fh-select"
                              v-model=" business_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="businessSimple"
                              :reduce="preview=>preview.id"
                              label="preview"
                              @input="updateProduct"
                              class="mr-50 "
                              style="min-width:300px"
                          />
                        </b-col>


                      </div>
            </b-form-group>
            <b-form-group class="justify-content-end">
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
              :pagination-options="{
        enabled: true,
        perPage:pageLength

      }"
              styleClass="vgt-table striped condensed no-outline"
          >

            <template
                slot="table-row"
                slot-scope="props"
            >

              <!-- Column: Name -->
              <span v-if="props.column.field === 'shipping_charge'">
          <p>৳ {{props.row.shipping_charge}}</p>
        </span>
              <span  v-else-if="props.column.field === 'seller_shipping_image'">
              <span     v-b-modal.modal-prevent-closing
                        @click="shippingDetails(props.row)">
              <b-badge pill variant="light-primary">View Shipping Details</b-badge>
            </span>
            </span>


              <span v-else-if="props.column.field === 'index'">
         <span>{{ (props.row.originalIndex+1)+(pageNumber>1?((pageNumber-1)*10):0)}}</span>
        </span>

              <!-- Column: Common -->
              <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <!--            <span class="text-nowrap ">-->
                  <!--              Showing 1 to-->
                  <!--            </span>-->
                  <!--            <b-form-select-->
                  <!--                v-model="pageLength"-->
                  <!--                :options="['3','5','10']"-->
                  <!--                class="mx-1"-->
                  <!--                @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
                  <!--            />-->
                  <span class="text-nowrap"> Total {{totalCount }} entries </span>
                </div>
                <div>
                  <b-pagination
                      :value="1"
                      :total-rows="totalCount"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>



        </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="modal-prevent-closing"
        centered
        ref="my-modal"
        :title="getModalTitle()"
        ok-only
        ok-title="OK"
    >
      <!--        <b-modal-->
      <!--          id="modal-prevent-closing"-->
      <!--          ref="my-modal"-->
      <!--          centered-->
      <!--          :title="getModalTitle()"-->
      <!--          ok-title="OK"-->
      <!--          cancel-variant="outline-secondary"-->
      <!--          @show="resetModal"-->
      <!--          @hidden="resetModal"-->
      <!--          @ok="handleOk"-->
      <!--      >-->
      <!--                <b-img :src="'http://asthaapi.bitflex.xyz/'+shipping_image" fluid alt="Responsive image"></b-img>-->
      <b-img :src="shipping_image" fluid alt="Responsive image"></b-img>
      <!--      <b-img :src="'http://api.nextcart.com/'+shipping_image" fluid alt="Responsive image"></b-img>-->

    </b-modal>
  </div>


</template>

<script>

import {
  BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,BImg,
  BModal,VBModal,BCardText,BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BModal,
    BRow,
    BCol,
    StatisticCardHorizontal,
    vSelect,
    BCardText
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      nameState: null,
      addNewMode:false,
      popupActive:true,
      modify:{
        product: null,
        sale_price: null,
      },
      item_id:null,
      business_id:null,
      pageNumber:1,
      pageLength: 10,
      shipping_image:null,
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          type:'number',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        {
          label: 'Order ID',
          field: 'order_id',
          tdClass: 'text-start',
          thClass:'text-center',
          sortable:false
        },
        {
          label: 'Shipping Charge',
          field: 'shipping_charge',
          tdClass: 'text-center',
          thClass:'text-center',
          // sortable:false
        },
        {
          label: 'Shipping Method',
          field: 'shipping_info.preview',
          tdClass: 'text-center',
          thClass:'text-center',
          // sortable:false
        },
        {
          label: 'Shipping Details',
          field: 'seller_shipping_image',
          tdClass: 'text-center',
          thClass:'text-center'
        },
        // {
        //   label: 'Commission',
        //   field: 'commission_amount',
        //   tdClass: 'text-start',
        //   thClass:'text-center'
        // },
        // {
        //   label: 'Receivable',
        //   field: 'seller_receivable',
        //   tdClass: 'text-center',
        //   thClass:'text-center'
        // },

      ],
      showAdd:false,
      showEdit:false,
      showDelete:false,
      rows: [],
      searchTerm: '',

    }
  },
  computed: {
    ...mapState('business', ["businessSimple","businessLoading","businessError"]),
    ...mapState('sellerShippingFinance', ["shippingFinance","totalCount","summary","shippingFinanceError","shippingFinanceLoading"]),
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    shippingFinance(newValue, oldValue) {
      if (newValue) {
        this.rows=this.shippingFinance
      }
    }
  },
  created() {
    this.$store.dispatch('business/fetchBusinessSimple')
    this.$store.dispatch('sellerShippingFinance/fetchShippingFinance', {count:this.pageLength, page:1})
    this.rows = this.shippingFinance
  },
  methods:{
    updateProduct() {
      this.$store.dispatch('sellerShippingFinance/fetchShippingFinanceByBusiness', {business_id:this.business_id,count:this.pageLength, page:1})
    },
    shippingDetails(data){
      this.shipping_image=data.seller_shipping_image
    },
    getModalTitle() {
      return 'Shipping Details'
    },
    handleChangePage(page) {
      this.pageNumber=page
      this.pageNumber=page
      if(this.business_id){
        this.$store.dispatch('sellerShippingFinance/fetchShippingFinanceByBusiness', {business_id: this.business_id, count: this.pageLength, page: page})
      }else{
        this.$store.dispatch('sellerShippingFinance/fetchShippingFinance', {count:this.pageLength, page:page})
      }

    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },

  }
}
</script>

<style lang="scss" >
.no-outline{
  border:none !important;
}
.f20{
  font-size: 15px !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>

