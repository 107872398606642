var menuItems = [];
var menu=[]
let items= [
    {
        title: 'Knowledge Category',
        route: 'knowledge-base-list',
        codename:'view_knowledgebaseCategory'
    },
    {
        title: 'Add Module',
        route: 'knowledge-base-add',
        codename:'add_knowledgebaseCategory'
    },
    {
        title: 'Knowledge Modules',
        route: 'knowledge-base',
        codename:'view_knowledgebase'
    },
    // {
    //     title: 'Affiliate Finance',
    //     route: 'affiliate-finance',
    //     codename:'view_affiliatefinance'
    // },
    // {
    //         title: 'Theme',
    //         route: 'theme',
    //         codename:'view_theme'
    //     },
    //     // {
    //     title: 'Country',
    //     route: 'database-country',
    //     codename:'view_country'
    //
    // },
    // {
    //     title: 'State',
    //     route: 'database-state',
    //     codename:'view_state'
    // },
    //
    // {
    //     title: 'Area',
    //     route: 'database-area',
    //     codename:'view_area'
    // },
    // {
    //     title: 'Product Categories',
    //     route: 'product-categories',
    //     codename:'view_category'
    // }

]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })

// if(menuItems.length!==0) {
menu.push( {
    header: 'Knowledge Base',
},)
menu.push({
    title: 'Knowledge Base',
    icon: 'TypeIcon',
    children: items
})
// }
export default menu;
