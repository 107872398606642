
var menuItems = [];
var menu=[]
let items= [
    {
        title: 'PG Log',
        route: 'gateway-log',
        codename:'view_pgwlog',
    },
    {
        title: 'PG History',
        route: 'gateway-history',
        codename:'view_paymenthistory',
    },


]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})

if(menuItems.length!==0) {
    menu.push({
        title: 'Payment Gateway',
        icon: 'ActivityIcon',
        children: menuItems
    })
}
export default menu;


