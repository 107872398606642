var menuItems = [];
var menu=[]
let items= [
    {
        title: 'All Orders',
        route: 'order-all',
        codename:"view_orders"

    },
    {
        title: 'Pending Orders',
        route: 'order-pending',
        codename:"view_orders"

    },
    {
        title: 'Processing Orders',
        route: 'order-processing',
        codename:'view_orders'

    },

    {
        title: 'Orders On Hold',
        route: 'order-onhold',
        codename:"view_orders"

    },
    {
        title: 'Shipped Orders',
        route: 'order-shipped',
        codename:"view_orders"

    },
    {
        title: 'In Hub Orders',
        route: 'order-inhub',
        codename:"view_orders"

    },
    {
        title: 'Delivered Orders',
        route: 'order-delivered',
        codename:"view_orders"

    },
    // {
    //     title: 'Cancelled Orders',
    //     route: 'order-cancelled',
    //     codename:'view_product'
    //
    // },
    {
        title: 'Cancelled Orders',
        route: 'order-cancelled',
        codename:'view_orders'

    },
    {
        title: 'Refunded Orders',
        route: 'order-refunded',
        codename:'view_orders'

    },
    {
        title: 'Returned Orders',
        route: 'order-returned',
        codename:'view_orders'

    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})


if(menuItems.length!==0) {
    menu.push(
        {
        title: 'Order Management',
        icon: 'BoxIcon',
        children: menuItems
    })
}
export default menu;
