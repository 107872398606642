var menuItems = [];
var menu=[]
let items= [
    {
        title: 'Home Slider',
        route: 'slider-images',
        codename:'view_homelargeslider',
    },
    {
        title: 'Home Ads',
        route: 'ads-manage',
        codename:'view_homeads',

    },


]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})

if(menuItems.length!==0) {
    menu.push({
        title: 'Ads Management',
        icon: 'AirplayIcon',
        children: menuItems
    })
}
export default menu;
