var menuItems = [];
var menu=[]
let items= [
    {
            title: 'Theme Types',
            route: 'theme-type',
            codename:'view_themetype'
        },
    {
            title: 'Theme',
            route: 'theme',
            codename:'view_theme'
        },
        // {
        //     title: 'Country',
        //     route: 'database-country',
        //     codename:'view_country'
        //
        // },
        // {
        //     title: 'State',
        //     route: 'database-state',
        //     codename:'view_state'
        // },
        //
        // {
        //     title: 'Area',
        //     route: 'database-area',
        //     codename:'view_area'
        // },
        // {
        //     title: 'Product Categories',
        //     route: 'product-categories',
        //     codename:'view_category'
        // }

    ]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })

// if(menuItems.length!==0) {
    menu.push({
        title: 'Theme',
        icon: 'CodesandboxIcon',
        children: items
    })
// }
export default menu;
