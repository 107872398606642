var menu = [];

let items = [

    {
        title: 'Seller Order',
        icon: 'BoxIcon',
        route:'order-seller',
        codename:'view_orders'
    },
    {
        title: 'Order Filter',
        icon: 'SearchIcon',
        route:'order-filter',
        codename:'view_orders'

    },
    {
        title: 'Report Generator',
        icon: 'PrinterIcon',
        route:'report-generator',
        codename:'view_dealsettings'

    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;

