var menuItems = [];
var menu=[]
let items= [
    {
            title: 'Business Types',
            route: 'business-type',
            codename:'view_businesstypes'
        },
        {
            title: 'Country',
            route: 'database-country',
            codename:'view_country'

        },
        {
            title: 'State',
            route: 'database-state',
            codename:'view_state'
        },

        {
            title: 'Area',
            route: 'database-area',
            codename:'view_area'
        },
    {
            title: 'Reserved Slugs',
            route: 'reserved-slug',
            codename:'view_reservedslug'
        },
        // {
        //     title: 'Product Categories',
        //     route: 'product-categories',
        //     codename:'view_category'
        // }

    ]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })

// if(menuItems.length!==0) {
    menu.push({
        title: 'Database',
        icon: 'DatabaseIcon',
        children: items
    })
// }
export default menu;
