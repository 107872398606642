var menuItems = [];
var menu=[]
let items= [
    {
        title: 'Server List',
        route: 'server-list',
        codename:'view_serverlist'
    },
    {
        title: 'Deploy List',
        route: 'deploy-list',
        codename:'view_deploymenturl'
    },

    // {
    //     title: 'Country',
    //     route: 'database-country',
    //     codename:'view_country'
    //
    // },
    // {
    //     title: 'State',
    //     route: 'database-state',
    //     codename:'view_state'
    // },
    //
    // {
    //     title: 'Area',
    //     route: 'database-area',
    //     codename:'view_area'
    // },
    // {
    //     title: 'Product Categories',
    //     route: 'product-categories',
    //     codename:'view_category'
    // }

]

// var permissions = JSON.parse(localStorage.getItem('permissions'));
// permissions = permissions === null ? [] : permissions;
//
// items.forEach(item=>{
//     permissions.forEach(e => {
//         if (e === item['codename']) {
//             menuItems.push(item)
//         }
//     })
//
// })

// if(menuItems.length!==0) {
menu.push({
    title: 'Server Management',
    icon: 'ServerIcon',
    children: items
})
// }
export default menu;
