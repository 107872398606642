
var menuItems = [];
let items= [
    {
        title: 'Category Commission',
        route: 'category-commission',
        codename:'change_category',
        icon: 'PercentIcon',

    },
    {
        title: 'Seller Shipping Methods',
        route: 'seller-shipping-method',
        codename:'view_sellerdeliverymethod',
        icon: 'TruckIcon',
    },
    {
        title: 'Shipping Methods',
        route: 'shipping-method',
        codename:'view_deliverymethod',
        icon: 'SendIcon',
    },
    {
        title: 'Payment Methods',
        route: 'payment-method',
        codename:'view_paymentmethods',
        icon: 'CreditCardIcon',
    }, {
        title: 'Coupon Code',
        route: 'coupon-manage',
        codename:'view_couponcodes',
        icon: 'DivideCircleIcon',
    },

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;

items.forEach(item=>{
    permissions.forEach(e => {
        if (e === item['codename']) {
            menuItems.push(item)
        }
    })

})

export default menuItems;

