var menu = [];

let items = [

    {
        title: 'Products List',
        route: 'product-list',
        icon:'BriefcaseIcon',
        codename: 'view_product'
    }

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;

