
var menu = [
];

let items = [

    {
        title: 'About Us',
        icon: 'CoffeeIcon',
        route: 'about-us',
        codename: 'view_siteinfo'

    }

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;
