var menu = [
];

let items = [

    {
        title: 'Terms of use',
        icon: 'PaperclipIcon',
        route: 'terms-use',
        codename: 'view_legaldocs'
    }

]

var permissions = JSON.parse(localStorage.getItem('permissions'));
permissions = permissions === null ? [] : permissions;


permissions.forEach(e => {
    items.forEach(item => {
        if (e === item['codename']) {
            menu.push(item)
        }
    })

})


export default menu;
